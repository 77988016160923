import React from 'react'
import {
	Navbar,
	// Hero,
	// Platform,
	// Contact,
	// Services,
	Footer,
} from '../components'
import AboutPesa from '../components/AboutPesa'
import Mission from '../components/Mission'
import Testimonial from '../components/Testimonials'
import Ceo from '../components/Ceo'
import { AboutWoman } from '../assets'

import styles from '../style'

const About = () => {
	return (
		<div className={` bg-dark w-full overflow-hidden`}>
			{/* <Navbar /> */}
			<AboutPesa img={AboutWoman} />
			<Mission />
			<Ceo />
			<Testimonial />

			<Footer />
		</div>
	)
}

export default About
