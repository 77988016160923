import React from 'react'

const Disclaimer = () => {
	return (
		<div className="flex justify-center items-center p-10">
			<p className=" text-[8px] text-dark font-poppins">
				<span className="font-bold ">Disclaimer:</span> The instalment amount
				shown is based on the loan product and term selected by you and is
				provided for illustration purposes only. Pesa Finance does not guarantee
				approval of any loan product or warrants in any way that the
				illustration and calculation is accurate or complete. Pesa Finance will
				not be liable in any way or form for reliance on or use of this
				calculator. The monthly installment shown includes interest, initiation
				fees, and service fees (calculated as a portion of the principal debt at
				inception).
			</p>
		</div>
	)
}

export default Disclaimer
