import React from 'react'
import styles from '../style'
import { CEO } from '../assets'

const Ceo = () => (
	<section
		id="CEO"
		className={`${styles.paddingX} flex flex-col justify-center items-center  min-h-40% ${styles.paddingY}`}>
		<div className="">
			<h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
				The
				<span className="text-gradient"> Captain </span>
				of The Ship
			</h1>{' '}
			<br />
		</div>
		<div className="flex justify-center flex-col items-center">
			<img
				src={CEO}
				alt="Isililo"
				className=" w-[50%] h-[50%] relative rounded-full "
			/>
			<h3 className="font-poppins font-medium ss:text-[32px] text-[24px] ss:leading-[75px] leading-[50px] text-white">
				Isililo Mzyece{' '}
			</h3>
			<p className="font-poppins font-normal text-sm leading-5 text-gray-400">
				Chief Executive Office{' '}
			</p>
		</div>
	</section>
)

export default Ceo
