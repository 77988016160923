const styles = {
	boxWidth: 'xl:max-w-[1280px] w-full',

	heading2:
		'font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full',

	featureCard:
		'bg-white m-10 p-10 rounded-md flex justify-center items-center flex-col shadow-lg cursor-pointer md:w-[540px] w-[300px] h-80 minlg:w-80 minlg:h-80',
	paragraph:
		'font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]',
	paragraphDark:
		'font-poppins font-normal text-dark text-[18px] leading-[30.8px]',

	flexCenter: 'flex justify-center items-center',
	flexStart: 'flex justify-center items-start',
	flexWrap: 'flex justify-center flex-wrap',

	paddingX: 'sm:px-16 px-6',
	paddingY: 'sm:py-16 py-6',
	padding: 'sm:px-16 px-6 sm:py-12 py-4',

	marginX: 'sm:mx-16 mx-6',
	marginY: 'sm:my-16 my-6',
}

export const layout = {
	section: `flex md:flex-row flex-col ${styles.paddingY}`,
	subSection: 'flex items-center w-full minmd:w-3/4',
	sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

	sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
	sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

	sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
}

export default styles
