import React, { useState } from 'react'
import styles from '../style'
import Slider from '@mui/material/Slider'
import { Footer } from '.'

const Calculator = () => {
	const [interestRate, setInterestRate] = useState(20)
	const [loanAmount, setLoanAmount] = useState()
	const [loanPeriod, setLoanPeriod] = useState(1)
	const [monthlyRepayment, setMonthlyRepayment] = useState(0)
	const [totalRepayment, setTotalRepayment] = useState(0)
	const [eligible, setEligible] = useState(false)
	const [canAfford, setCanAfford] = useState(0)
	const [netSalary, setNetSalary] = useState()
	const [basicSalary, setBasicSalary] = useState()
	const [maxBorrow, setMaxBorrow] = useState(0)

	const calculate = () => {
		const monthly = (loanAmount * (1 + (interestRate * loanPeriod) / 100)) / loanPeriod
		const afford = netSalary - (basicSalary * 0.4)
		const max = (afford * loanPeriod)/(1 + (interestRate * loanPeriod) / 100)

		setCanAfford(afford)
		setMonthlyRepayment(monthly)
		setTotalRepayment(monthly * loanPeriod)
		setMaxBorrow(max)

		// if(canAfford > monthlyPayment) {
		//     cannotAfford = false
		// } else {
		//     document.getElementById("result").innerHTML = `The maximum you can afford to borrow is ${canAfford}`
		// }
		if (monthly > afford) {
			setEligible(false)
		} else {
			setEligible(true)
		}
	}

	return (
		<div
			className={` ${styles.paddingX} bg-blob bg-cover flex justify-center items-center flex-col h-full`}>
			<h1 className="font-poppins font-semibold flex-1 ss:text-[32px] text-[12px] ss:leading-[60px] leading-[35px] text-dark mt-6">
				Check Eligibility and Monthly Repayments
			</h1>
			<form className="flex justify-around items-center flex-col">
				<div className="flex justify-around items md:flex-row flex-col center w-full">
					<div className="flex items-center flex-col py-2 w-[320px] m-5">
						<label
							htmlFor="loanAmount"
							className="text-xs font-[poppins] text-dark">
							Enter The Amount You'd like to borrow
						</label>
						<input
							type="number"
							className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-blue-600 peer"
							id="loanAmount"
							value={loanAmount}
							onChange={(e) => setLoanAmount(e.target.value)}
						/>
					</div>
					<div className="flex items-center flex-col  py-2 w-[320px] m-5">
						<label
							htmlFor="basicSalary"
							className="text-xs font-[poppins] text-dark">
							What is your basic monthly income
						</label>
						<input
							type="number"
							className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-blue-600 peer"
							id="basicSalary"
							value={basicSalary}
							onChange={(e) => setBasicSalary(e.target.value)}
						/>
					</div>
					<div className="flex items-center flex-col  py-2 w-[320px] m-5">
						<label
							htmlFor="netSalary"
							className="text-xs font-[poppins] text-dark">
							What is your NET monthly income?
						</label>
						<input
							type="number"
							className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-blue-600 peer"
							id="netSalary"
							value={netSalary}
							onChange={(e) => setNetSalary(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex items-center flex-col  py-2 w-11/12 m-5">
					<label
						htmlFor="loanPeriod"
						className="font-poppins font-semibold flex-1 ss:text-[22px] text-[12px] ss:leading-[60px] leading-[35px] text-dark mt-6">
						Loan Period: {loanPeriod} {loanPeriod == 1 ? 'Month' : 'Months'}{' '}
					</label>

					<Slider
						value={loanPeriod}
						aria-label="Default"
						valueLabelDisplay="auto"
						onChange={(e) => setLoanPeriod(e.target.value)}
						min={1}
						max={12}
					/>
				</div>
				{/* <div className="flex items-center flex-col  py-2 w-[120px] m-5">
					<label
						htmlFor="interestRate"
						className="text-xs font-[poppins] text-dark">
						Interest
					</label>
					<h2 className="block py-2.5 px-0 w-full relative r text-m text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-blue-600 peer"
						id="interestRate"> {interestRate}%</h2>
		
				</div> */}
			</form>
			<div className="w-screen">
				<div className="flex flex-col justify-center items-center">
					<h2 className="font-poppins font-semibold flex-1 ss:text-[32px] text-[12px] ss:leading-[60px] leading-[35px] text-dark mt-6">
						YOUR MONTHLY <span className="text-secondary">REPAYMENTS</span> WILL
						BE
					</h2>
					<div className="font-poppins font-semibold flex-1 ss:text-[32px] text-[12px] ss:leading-[60px] leading-[35px] text-dark">
						K{Math.ceil(monthlyRepayment)}
					</div>
				</div>
				<div className="flex justify-around flex-row items-center p-20">
					<div className="font-poppins font-normal flex-1 ss:text-[22px] text-[12px] flex flex-col justify-center text-center text-dark">
						<h1>TOTAL REPAYMENT</h1>
						K{Math.ceil(totalRepayment)}
					</div>
					{/*  */}
					<div className="font-poppins font-normal flex-1 ss:text-[22px] text-[12px] flex flex-col justify-center text-center text-dark">
						<h1>FOR {loanPeriod} MONTHS YOU CAN BORROW A MAXIMUM OF</h1>
						K{Math.abs(Math.floor(maxBorrow))}
					</div>
				</div>
				<div>
					<div className="flex justify-center items-center flex-col">
						<button
							type="button"
							class={`bg-secondary text-[16px] text-white font-poppins py-4 px-8 rounded-full`}
							onClick={calculate}>
							Calculate
						</button>
						<div className="py-5">
							{eligible ? (
								<div className="flex justify-center items-center flex-col">
									<p>
										You're eligible for the loan click the button below to apply
									</p>
									<a
										href="https://pesa.lendbox.io/"
										class={`bg-primary text-[16px] text-white font-poppins py-4 mt-5 px-8 rounded-full`}>
										Apply here
									</a>
								</div>
							) : (
								<div>
									You're <strong>NOT</strong> eligible for the loan, reduce your request and calculate
									to check your eligibility{' '}
								</div>
							)}{' '}
						</div>
					</div>{' '}
				</div>
			</div>
		</div>
	)
}

export default Calculator
