import React from 'react'
import styles from '../style'
import { HeroImg, wave } from '../assets'
import { Link as LinkScroll } from 'react-scroll'

const Hero = () => (
	<section
		id="home"
		className={`${styles.paddingX} min-h-screen grid md:grid-cols-2 grid-cols-1 relative ${styles.paddingY}`}>
		<div
			className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 `}>
			<div className="flex flex-row justify-between items-center w-full">
				<h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
					Apply and Get Your
					<span className="text-gradient"> Loan </span> With Us!
				</h1>
			</div>
			<p className={`${styles.paragraph} max-w-[670px] mt-5 `}>
				With a few simple clicks and uploads, you could be on your way to
				getting a loan today. Why not give us a try and see? Simply click below
				to get started right away!
			</p>
			<div className="mt-5 md:mb-32 mb-0">
				<LinkScroll to="platform">
					<button
						class={`bg-secondary text-[16px] text-white font-poppins py-4 px-8 rounded-full`}>
						Get Started
					</button>
				</LinkScroll>
			</div>
		</div>
		<div className="md:mb-20 m-0">
			<img
				src={HeroImg}
				alt="Hero Image"
				className=" w-[100%] h-[100%] relative "
			/>
			<div className={`absolute z-[0] w-[40%] h-[35%] top-0 white__gradient`} />
		</div>
		<img src={wave} alt="wave" className="absolute bottom-0 left-0 right-0" />
	</section>
)

export default Hero
