import React from 'react'
import styles from '../style'

const Mission = () => (
    <section id="About" className={`${styles.paddingX} min-h-40% grid md:grid-cols-2 grid-cols-1 relative ${styles.paddingY}`}>
        <div className='justify-center items-center flex flex-col'>
        <h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
					Our
					<span className="text-gradient"> Values </span>
				</h1> 
                <br /><br />

                <p className={`${styles.paragraph} max-w-[670px] mt-5 `}>• Service - Our customers are at the centre of our actions. <br />
• Integrity - We keep our promises. <br />
• Diversity - We find strength in diversity. <br />
• Excellence - we apply our skills to deliver the best results.</p>
        </div>
        <div className='justify-center items-center flex flex-col'>
        <h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
					Our
					<span className="text-gradient"> Mission </span>
				</h1> <br /> 
                <p className={`${styles.paragraph} max-w-[670px] mt-5 `}>We endeavour to help our customers actualise their dreams
by creating credit opportunities to foster entrepreneurial
activities that result into social and economic empowerment.</p>
        </div>
    </section>
)

export default Mission