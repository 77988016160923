import React, { useState } from 'react'
import { navLinks } from '../constants'
import { close, menu, logo } from '../assets'
import { Link } from 'react-router-dom'
import styles from '../style'
import { Link as LinkScroll } from 'react-scroll'

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<nav
			className={`${styles.paddingX} w-full flex py-6 justify-between items-center navbar`}>
			<Link to="/">
				<img src={logo} alt="logo" className="w-[124px] h-[32px]" />
			</Link>
			<ul className="list-none sm:flex hidden justify-center items-center flex-1">
				{navLinks.map((nav, index) => (
					<li
						key={nav.id}
						className={`font-poppins text-[16px] ${
							index === navLinks.length - 1 ? 'mr-0' : 'mr-10'
						} text-white cursor-pointer hover:text-secondary  font-normal`}>
						{nav.id == 'about' ? (
							<Link to="/about">{nav.title}</Link>
						) : (
							<LinkScroll to={`${nav.id}`}> {nav.title} </LinkScroll>
						)}
					</li>
				))}
			</ul>
			<div className="flex sm:hidden flex-1 justify-end items-center">
				<img
					src={isOpen ? close : menu}
					className="cursor-pointer w-[28px] h-[28px]"
					onClick={() => setIsOpen(!isOpen)}
				/>
				<div
					className={`${
						isOpen ? 'flex' : 'hidden'
					} p-6 bg-black-gradient absolute top-20 z-50 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}>
					<ul className="list-none flex flex-col justify-center items-center flex-1">
						{navLinks.map((nav, index) => (
							<li
								key={nav.id}
								className={`font-poppins text-[16px] ${
									index === navLinks.length - 1 ? 'mb-0' : 'mb-4'
								} text-white hover:text-secondary cursor-pointer font-normal`}>
								{nav.id == 'about' ? (
									<Link to="/about">{nav.title}</Link>
								) : (
									<LinkScroll to={`${nav.id}`}> {nav.title} </LinkScroll>
								)}
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className="sm:block hidden">
				<a
					href="https://lendbox.io/login"
					class={`bg-primary hover:bg-secondary text-[16px] cursor-pointer text-white font-poppins py-4 px-6 rounded-full`}>
					Admin
				</a>
			</div>
		</nav>
	)
}

export default Navbar
