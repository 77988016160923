import React from 'react'

const Banner = () => {
	return (
		<div className="flex justify-center items-center bg-dark  bg-cover h-[250px] w-screen">
			<h1 className="font-poppins font-semibold text-center flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
				Loan Calculator
			</h1>
		</div>
	)
}

export default Banner
