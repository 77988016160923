import React from 'react'
import styles from '../style'


const AboutPesa = ({img}) => (
    <section id="About" className={`${styles.paddingX} min-h-screen grid md:grid-cols-2 grid-cols-1 relative ${styles.paddingY}`}>
        <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 `}>
        <div className="flex flex-row justify-between items-center w-full">
				<h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
					About
					<span className="text-gradient"> Pesa </span>
				</h1>
			</div>
			<p className={`${styles.paragraph} max-w-[670px] mt-5 `}>
            Pesa Finance Zambia Ltd was incorporated on 26th January
2021. It is a non-deposit taking Non-Bank Financial Institution
that is in the process of obtaining its operating licence from
Bank of Zambia. It intends to be fully operational by 1st
May 2021 by offering Credit facilities to both the formal and
informal sector.


			</p>
			{/* <div className="mt-5 md:mb-32 mb-0">
				<button
					class={`bg-secondary text-[16px] text-white font-poppins py-4 px-8 rounded-full`}>
					Get Started
				</button>
			</div> */}
        </div>
        <div className="md:mb-20 m-0">
			<img
				src={img}
				alt="Lady"
				className=" w-[100%] h-[100%] relative "
			/>
			<div className={`absolute z-[0] w-[40%] h-[35%] top-0 white__gradient`} />
		</div>
    </section>
)

export default AboutPesa