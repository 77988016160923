import { facebook, instagram, linkedin, twitter } from '../assets'

export const navLinks = [
	{
		id: 'home',
		title: 'Home',
	},
	{
		id: 'about',
		title: 'About',
	},
	{
		id: 'services',
		title: 'Services',
	},
	{
		id: 'contact',
		title: 'Contact',
	},
]
export const services = [
	{
		iconText: 'SME PRODUCTS',
		iconDesc:
			'Business Loans for working capital Invoice discounting for SMEs 1 to 6 months. Asset Financing purchase assets for use in the business',
	},
	{
		iconText: 'CONSUMER PRODUCTS',
		iconDesc:
			'Salary advances – these loans have tenors of 1-3 months. Personal loans – these loans have tenors of 1-12 months.',
	},
	{
		iconText: 'MORE SME PRODUCTS',
		iconDesc:
			'SME Loans: for individual borrowers Village Banking Loans - these are groups of low-income entrepreneurs who come together to share and guarantee one another’s loans',
	},
	{
		iconText: 'ADVISORY FEES & BROKERAGE',
		iconDesc:
			'Pesa Finance will support its SME clients to access cheaper funds from other Lenders through deal structuring. It will broker where necessary facilities and structures which may not be readily available at Pesa Finance.',
	},
]

export const footerLinks = [
	{
		title: 'Useful Links',
		links: [
			{
				name: 'Content',
				link: 'https://www.hoobank.com/content/',
			},
			{
				name: 'How it Works',
				link: 'https://www.hoobank.com/how-it-works/',
			},
			{
				name: 'Create',
				link: 'https://www.hoobank.com/create/',
			},
			{
				name: 'Explore',
				link: 'https://www.hoobank.com/explore/',
			},
			{
				name: 'Terms & Services',
				link: 'https://www.hoobank.com/terms-and-services/',
			},
		],
	},
	{
		title: 'Community',
		links: [
			{
				name: 'Help Center',
				link: 'https://www.hoobank.com/help-center/',
			},
			{
				name: 'Partners',
				link: 'https://www.hoobank.com/partners/',
			},
			{
				name: 'Suggestions',
				link: 'https://www.hoobank.com/suggestions/',
			},
			{
				name: 'Blog',
				link: 'https://www.hoobank.com/blog/',
			},
			{
				name: 'Newsletters',
				link: 'https://www.hoobank.com/newsletters/',
			},
		],
	},
	{
		title: 'Partner',
		links: [
			{
				name: 'Our Partner',
				link: 'https://www.hoobank.com/our-partner/',
			},
			{
				name: 'Become a Partner',
				link: 'https://www.hoobank.com/become-a-partner/',
			},
		],
	},
]

export const socialMedia = [
	{
		id: 'social-media-1',
		icon: instagram,
		link: 'https://www.instagram.com/',
	},
	{
		id: 'social-media-2',
		icon: facebook,
		link: 'https://www.facebook.com/',
	},
	{
		id: 'social-media-3',
		icon: twitter,
		link: 'https://www.twitter.com/',
	},
	{
		id: 'social-media-4',
		icon: linkedin,
		link: 'https://www.linkedin.com/',
	},
]
