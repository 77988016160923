import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { contactImg } from '../assets'
import Swal from 'sweetalert2'

const Contact = () => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [message, setMessage] = useState('')

	const form = useRef()

	const clearForm = () => {
		setName('')
		setEmail('')
		setPhone('')
		setMessage('')
	}

	const sendEmail = (e) => {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_z6vheqj',
				'template_r2f1czf',
				e.target,
				'uezNhI4xn8zfwYvC9',
			)
			.then(
				(res) =>
					Swal.fire({
						title: 'Hurray!!',
						text: 'Mail has been sent successfully.',
						type: 'success',
					}) && clearForm(),
			)
			.catch(
				(err) =>
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Something went wrong!',
						footer: '<a href="">Why do I have this issue?</a>',
					}) && console.log(err),
			)
	}

	return (
		<div id="contact" class="bg-dark text-gray-100 md:px-32 px-10 py-12">
			<div class="text-center w-full"></div>
			<div class="max-w-screen-xl px-10 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-dark rounded-lg shadow-lg">
				<div class="flex flex-col justify-between">
					<div>
						<h2 class="text-4xl lg:text-5xl font-bold leading-tight">
							Lets talk about everything!
						</h2>
						<div class="text-gray-700 mt-8">
							Hate forms? Send us an <span class="underline">email</span>{' '}
							instead.
						</div>
					</div>
					<div class="mt-8 text-center">
						<img src={contactImg} />
					</div>
				</div>
				<form onSubmit={sendEmail} ref={form}>
					<div>
						<span class="uppercase text-sm text-gray-600 font-bold">
							Full Name
						</span>
						<input
							class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
							type="text"
							placeholder=""
							value={name}
							name="user_name"
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div class="mt-8">
						<span class="uppercase text-sm text-gray-600 font-bold">Email</span>
						<input
							class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
							type="text"
							name="user_email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div class="mt-8">
						<span class="uppercase text-sm text-gray-600 font-bold">
							Phone Number
						</span>
						<input
							class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
							type="text"
							name="user_phone"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						/>
					</div>
					<div class="mt-8">
						<span class="uppercase text-sm text-gray-600 font-bold">
							Message
						</span>
						<textarea
							value={message}
							name="message"
							onChange={(e) => setMessage(e.target.value)}
							class="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
					</div>
					<div class="mt-8">
						<button
							type="submit"
							class="uppercase text-sm font-bold tracking-wide bg-secondary text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
							Send Message
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Contact
