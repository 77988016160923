import React from 'react'
import { Footer } from '../components'
import Calculator from '../components/Calculator'
import Disclaimer from '../components/Disclaimer'
import Banner from '../components/Banner'

const CalculatorPage = () => {
	return (
		<div className="overflow-hidden">
			<Banner />
			<Calculator />
			<Disclaimer />
			<Footer />
		</div>
	)
}

export default CalculatorPage
