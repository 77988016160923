import React from 'react'
import { Link } from 'react-router-dom'
import { blob_2, iphone } from '../assets'
import styles from '../style'

const Platform = () => {
	return (
		<div
			id="platform"
			className={` md:m-32 m-10 px-10 max-h-[50%] grid md:grid-cols-2 grid-cols-1 relative bg-white rounded-xl shadow-lg `}>
			<div className="ml-10">
				<img
					src={iphone}
					alt="Hero Image"
					className=" md:w-[470px] w-[100%] md:h-[750px] h-[100%] z-[5]"
				/>
			</div>
			<div className={` ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 `}>
				<div className="flex flex-row justify-between items-center w-full">
					<h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[60px] leading-[35px] text-dark">
						We have a simple
						<span className="text-secondary"> online </span> application form
					</h1>
				</div>
				<p className={`${styles.paragraphDark} max-w-[670px] mt-5 `}>
					Get your loans on the go with Pesa Finance.
				</p>
				<Link to="/calculator" className="mt-5 mb-10">
					<button
						class={`bg-secondary hover:bg-secondary text-[16px] text-white font-poppins py-4 px-8 rounded-full`}>
						Apply Now!
					</button>
				</Link>
			</div>
			{/* <img
				src={blob_2}
				alt="wave"
				className="absolute bottom-0 left-0 right-0"
			/> */}
		</div>
	)
}

export default Platform
