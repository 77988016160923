import React from 'react'
import {
	Navbar,
	Hero,
	Platform,
	Contact,
	Services,
	Footer,
	Calculator,
	About,
} from '../components'
import { landingaboutwoman } from '../assets'
import AboutPesa from '../components/AboutPesa'
import TestimonialMain from '../components/TestimonialMain'

import styles from '../style'

const Landing = () => {
	return (
		<div className={` bg-dark w-full overflow-hidden`}>
			<Navbar />
			<Hero />
			{/* <AboutPesa img={landingaboutwoman} /> */}

			<Platform />
			<Services />
			<TestimonialMain />
			<Contact />
			<Footer />
		</div>
	)
}

export default Landing
