import React from 'react'

import { services } from '../constants'
import styles, { layout } from '../style'

const ServicesCard = ({ iconText, iconDesc }) => (
	<div className={`${styles.featureCard}`}>
		<h1 className={`text-secondary font-bold text-xl mb-5`}>{iconText}</h1>
		<p className={`  font-poppins font-light text-md minmd:leading-16`}>
			{iconDesc}
		</p>
	</div>
)

const Services = () => {
	return (
		<div
			id="services"
			className={`${layout.section} bg-blob bg-cover md:px-0 px-5`}>
			<div className={`${layout.subSection} flex-col text-center`}>
				<div>
					<h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] text-dark">
						Our<span className="text-secondary"> Services</span>
					</h1>
					<p className={`${styles.paragraphDark}`}>
						We have four major products that cover a fairly large sector that
						may include your business or needs.
					</p>
				</div>
				<div className={`grid md:grid-cols-2 grid-cols-1 mr-2`}>
					{services.map((service, index) => (
						<ServicesCard
							key={index}
							iconText={service.iconText}
							iconDesc={service.iconDesc}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default Services
