import React from 'react'
import styles from '../style'
import { FullLendboxlogo, PMEC } from '../assets'

const Testimonial = () => (
    <section id="About" className={`${styles.paddingX} min-h-30% grid md:grid-cols-2 grid-cols-1 relative ${styles.paddingY}`}>
        <div className='justify-center items-center flex flex-col'>
        <h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
					Our
					<span className="text-gradient"> Partnerships </span>
				</h1> 
                <br /><br />
                <img
				src={PMEC}
				alt="PMEC"
				className=" w-[60%] h-[60%] relative "
			/>
                
        </div>
        <div className='justify-center items-center flex flex-col'>
        <h1 className="font-poppins font-semibold flex-1 ss:text-[52px] text-[32px] ss:leading-[75px] leading-[50px] text-white">
					 
					<span className="text-gradient">   </span>
				</h1> 
        <br />
        <img
				src={FullLendboxlogo}
				alt="Lendbox"
				className=" w-[80%] h-[80%] relative"
			/>
                
        </div>
    </section>
)

export default Testimonial